import Script from 'next/script';
import { useEffect, useState } from 'react';

const TarteAuCitron = () => {
  const [isTarteAuCitronLoaded, setIsTarteAuCitronLoaded] = useState(false);
  useEffect(() => {
    if (!isTarteAuCitronLoaded) return;
    window.tarteaucitron.init({
      privacyUrl: '' /* Privacy policy url */,
      bodyPosition:
        'bottom' /* or top to bring it as first element for accessibility */,

      hashtag: '#tarteaucitron' /* Open the panel with this hashtag */,
      cookieName: 'cookieConsent' /* Cookie name */,

      orientation: 'middle' /* Banner position (top - bottom) */,

      groupServices: false /* Group services by category */,
      serviceDefaultState: 'wait' /* Default state (true - wait - false) */,

      showAlertSmall: false /* Show the small banner on bottom right */,
      cookieslist: [
        'webTvDomain-${hostname}',
        'messageLiked',
        'NEXT_LOCALE',
        'uuid',
        'formData',
        'pollQuestionIds',
        'contenttoShowId',
        'contentToShowType'
      ] /* Show the cookie list */,

      closePopup: false /* Show a close X on the banner */,

      showIcon: true /* Show cookie icon to manage cookies */,
      iconSrc: "https://www.streamfizz.com/wp-content/uploads/2023/05/cookie.png", /* Optionnal: URL or base64 encoded image */
      iconPosition:
        'BottomLeft' /* BottomRight, BottomLeft, TopRight and TopLeft */,

      adblocker: false /* Show a Warning if an adblocker is detected */,

      DenyAllCta: true /* Show the deny all button */,
      AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
      highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,

      handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,

      removeCredit: false /* Remove credit link */,
      moreInfoLink: true /* Show more info link */,

      useExternalCss: false /* If false, the tarteaucitron.css file will be loaded */,
      useExternalJs: false /* If false, the tarteaucitron.js file will be loaded */,

      //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

      readmoreLink: '' /* Change the default readmore link */,

      mandatory: true /* Show a message about mandatory cookies */,
      mandatoryCta: true /* Show the disabled accept button when mandatory on */
    });
    window.tarteaucitron.user.matomoId = 1;
    (window.tarteaucitron.job = window.tarteaucitron.job || []).push(
      'matomocloud'
    );
    window.tarteaucitron.user.matomoHost = 'https://streamfizz.matomo.cloud/';
    window.tarteaucitron.user.matomoCustomJSPath =
      '//cdn.matomo.cloud/streamfizz.matomo.cloud/matomo.js';
  }, [isTarteAuCitronLoaded]);

  return (
    <>
     <div id="tarteaucitronRoot"></div> 
    <Script
      id="tarteaucitronScript"
      src="/js/tarteaucitron/tarteaucitron.js"
      onLoad={() => {
        setIsTarteAuCitronLoaded(true);
        console.log('tarteaucitron from onload', tarteaucitron);
      }}
      onError={(e) => {
        console.log('error: ', e);
      }}
    />
    </>
  );
};

export default TarteAuCitron;
