import Head from 'next/head';
import { useEffect, useState } from 'react';
import { getContent, getHeadInfos } from '../../utils/functions/contentParams';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import DOMPurify from 'dompurify';

const HeadSEO = function ({ media, live, playlist, locale }) {
  const [urlsToPrefetch, setUrlsToPrefetch] = useState([]);
  const { content, contentType, isLive } = getContent(media, live, playlist);
  const { cssOverload } = GetBrandSettingFromRecoil();
  const { title, image, description } =
    content && getHeadInfos(content, isLive);
  const cleanCss =
    cssOverload?.length &&
    DOMPurify.sanitize(cssOverload, { ADD_TAGS: ['style'] });

  useEffect(() => {
    if (!media?.thumbnailVttCdnUrl || urlsToPrefetch.length > 0) return;
    fetch(media.thumbnailVttCdnUrl)
      .then((response) => response.text())
      .then((data) => {
        const urls = data.match(/https?:\/\/[^\s]+/g);
        urls && setUrlsToPrefetch(urls);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [media, urlsToPrefetch]);

  return (
    <Head>
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* {sourceRaw && <meta property="og:video" content={sourceRaw} />} */}
      {cssOverload?.length && <style>{cleanCss}</style>}
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_PLAYER_URL}/${contentType}/${content.id}`}
      />
      {/*       {playlist && (
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"
          rel="stylesheet"
        />
      )} */}
      <meta property="og:locale" content={locale} />
      {urlsToPrefetch.length > 0 &&
        urlsToPrefetch.map((url) => (
          <link key={url} rel="prefetch" href={url} as="image" />
        ))}
    </Head>
  );
};

export default HeadSEO;
