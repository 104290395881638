import { convertFromRaw, convertToRaw } from 'draft-js';

// create function who retourn pagesetting or embedsetting according if it's an embed or not and if there are pagesetting or not
export const getBrandSettingType = (content, isEmbed) =>
  isEmbed ? content?.embedSetting : content?.pageSetting;

export const getRichTextForCurrentMedia = (playlist, contentId) => {
  const currentMedia = playlist?.content.find(
    (content) => content.id === contentId
  );

  if (currentMedia) {
    const pageSetting = currentMedia[currentMedia.type]?.pageSetting;

    if (pageSetting && pageSetting.description) {
      return pageSetting.description;
    }
  }
};

export const convertDraftJsToPlainText = (description) => {
  if (!description) return;
  const contentState = convertFromRaw(description);
  const rawContentState = convertToRaw(contentState);
  const blocks = rawContentState?.blocks;

  const text = blocks.map((block) => block.text).join('\n');
  return text;
};
